// import { faker } from '@faker-js/faker';
// import { sample } from 'lodash';

import { get } from '../utils/request';



const users = [];
const fetchUsers = async () => {
  
try {
  
await get('/api/v2/admins/merchants/requests').
then((res) => {

  console.log("this is approve merchant", res.data);
  


const fetchUsers = res.data.map((user)=> ({
  id: user.id,
  avatarUrl: `/assets/images/avatars/avatar_${ 1 + 1}.jpg`,
  name: user.amount + user.currency,
  company: user.userWalletAddress,
  isVerified: user.email,
  status: user.phoneNumber,
  status1: user.status,
  userid: user.userId,
  role: user.username,
  address: user.transactionHashId,
  length: user.length, 
})); 



users.push(...fetchUsers);

} );

} catch (error) {
  console.error(error); // Handle the error
}
};
fetchUsers();


export default users;
