import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, FormControlLabel, Snackbar, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import { post } from '../../../utils/request';






// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  // const history = useHistory();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');


  const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState('');

  const [loading, setloading] = useState(false);

  const [formData, setFormData] = useState({ email: '', password: '' });

  const handleClick = async () => {
    try {
      setloading(true);
      localStorage.removeItem('access_token');
      localStorage.removeItem('id');
    await post('/api/v2/admins/login', formData).
    then((res) => {
      console.log("this is login response", res);
      localStorage.setItem('access_token',res.token);
      localStorage.setItem('id',res.id);
      setSnackbarMessage('Login successful!');
      setSnackbarOpen(true);
    } )
    // { replace: true }
      // console.log("res2", response.token);
      setloading(false);
      
    navigate('/dashboard');
    window.location.reload();
  } catch (error) {
    console.error(error); // Handle the error
    setSnackbarMessage('Incorrect email or password.');
      setSnackbarOpen(true);
      setloading(false);
  }
  };


  const handleForget = async () => {
    if (!formData.email) {
      setSnackbarMessage('Please input the email.');
      setSnackbarOpen(true);
      return;
    }

    // const formData = new FormData();
    // formData.append('email', formData.email);
    try {
      setloading(true);
    await post('/api/v2/admins/forgot-password', formData).
    then(() => {
      setSnackbarMessage('Password reset email sent successfully!');
        setSnackbarOpen(true);
      // console.log("this is response", res)
      // console.log("email1", formData);
      // console.log("email2", formData.email)
    } )
      // console.log(response.data);
      // console.log(email);
      setloading(false);
    // navigate('/dashboard', { replace: true });
  } catch (error) {
    // console.log("this is response", res);
    // console.log("email1", formData);
    // console.log("email2", formData.email)
    console.error(error); // Handle the error
    setloading(false);
  }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={formData.email}    onChange={handleInputChange} /> 
        

        <TextField 
          name="password"
          label="Password" 
          value={formData.password}
          onChange={handleInputChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <FormControlLabel
          control={<Checkbox name="remember" />}
          label="Remember me"
          onChange={handleInputChange}
        />
        <Link variant="subtitle2" style={{ cursor: 'pointer' }} underline="hover"onClick={handleForget} >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loading}>
        Login
      </LoadingButton>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
}
