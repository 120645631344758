// import { faker } from '@faker-js/faker';
// import { sample } from 'lodash';

import { get } from '../utils/request';



const users = [];
const fetchUsers = async () => {
  
try {         
  
await get('/api/v2/admins/users/get-all').
then((res) => {

  // console.log("this is response", res.data);
  



const fetchUsers = res.data.map((user)=> ({
  id: user.id,
  avatarUrl: `/assets/images/avatars/avatar_${ 1 + 1}.jpg`,
  name: `${user.firstName} ${user.lastName}`,
  company: user.id,
  isVerified: user.email,
  status: user.phoneNumber,
  role: user.username,
  length: user.length,
})); 



users.push(...fetchUsers);

} );

} catch (error) {
  console.error(error); // Handle the error
}
};
fetchUsers();

// const users = [...Array(24)].map((_, index) => ({
//   id: this.response.data.id,
//   avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
//   name: faker.name.fullName(),
//   company: faker.company.name(),
//   isVerified: faker.datatype.boolean(),
//   status: sample(['active', 'banned']),
//   role: sample([
//     'Leader',
//     'Hr Manager',
//     'UI Designer',
//     'UX Designer',
//     'UI/UX Designer',
//     'Project Manager',
//     'Backend Developer',
//     'Full Stack Designer',
//     'Front End Developer',
//     'Full Stack Developer',
//   ]),
// }));

// ----------------------------------------------------------------------

// const users = [...Array(24)].map((_, index) => ({
//   id: faker.datatype.uuid(),
//   avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
//   name: faker.name.fullName(),
//   company: faker.company.name(),
//   isVerified: faker.datatype.boolean(),
//   status: sample(['active', 'banned']),
//   role: sample([
//     'Leader',
//     'Hr Manager',
//     'UI Designer',
//     'UX Designer',
//     'UI/UX Designer',
//     'Project Manager',
//     'Backend Developer',
//     'Full Stack Designer',
//     'Front End Developer',
//     'Full Stack Developer',
//   ]),
// }));

export default users;
