export const USER_ACCESS_TOKEN = 'access_token';

export const ADMIN_ACCESS_TOKEN = 'admin_access_token';

export const OPERATOR_ACCESS_TOKEN = 'operator_access_token';

export const JUDGE_ACCESS_TOKEN = 'judge_access_token';

export const AUTH_HEADER = 'Authorization'

export const REF_KEY = "__ref-code__"