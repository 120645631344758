// import { faker } from '@faker-js/faker';
// import { sample } from 'lodash';

import { get } from '../utils/request';



const users = [];
const fetchUsers = async () => {
  
try {
  
await get('/api/v2/transactions').
then((res) => {

  console.log("Crypto Transactions", res);
  



const fetchUsers = res.map((user)=> ({
  id: user.id,
  avatarUrl: `/assets/images/avatars/avatar_${ 1 + 1}.jpg`,
  name: `${user.amount}  ${user.currency} Fee: ${user.transactionFee}`,
  company: `${user.paymentMethod} ${user.transactionType} ${user.assetName}`,
  isVerified: new Date(user.createdAt).toLocaleString(),
  status: user.status,
  role: `${user.userId} ${user.merchantId}`,
  length: user.length,
})); 



users.push(...fetchUsers);

} );

} catch (error) {
  console.error(error); // Handle the error
}
};
fetchUsers();



export default users;
