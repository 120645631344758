import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import { put } from '../../../utils/request';

// import { useParams } from 'react-router-dom';





// ----------------------------------------------------------------------

export default function ResetForm() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState(" ");

  const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState('');

  const [formData, setFormData] = useState({ newPassword: '', confirmPassword: '' });

  const handleClick = async () => {

    if (formData.newPassword === formData.confirmPassword) {
        // Send a request to reset the password with the token and new password
        // Handle success or error response accordingly

    try {
    await put(`/api/v2/admins/reset-password/${token}`, formData).
    then((res) => {
      console.log("this is response", res);
    //   localStorage.setItem('USER_ACCESS_TOKEN',res.token)
    } )
      // console.log("res2", response.token);
    navigate('/login', { replace: true });
  } catch (error) {
    console.error(error); // Handle the error
  }
} else {
    // Show an error message indicating that the passwords do not match
//     const errorMessage = "Passwords do not match. Please try again.";
//   document.getElementById("error-message").textContent = errorMessage;
  setErrorMessage("Passwords do not match. Please try again.");
  }
  };


//   const handleForget = async () => {
//     // const formData = new FormData();
//     // formData.append('email', formData.email);
//     try {
//     await post('/api/v2/admins/forgot-password', formData).
//     then((res) => {
//       console.log("this is response", res)
//       // console.log("email1", formData);
//       // console.log("email2", formData.email)
//     } )
//       // console.log(response.data);
//       // console.log(email);

//     // navigate('/dashboard', { replace: true });
//   } catch (error) {
//     // console.log("this is response", res);
//     // console.log("email1", formData);
//     // console.log("email2", formData.email)
//     console.error(error); // Handle the error
//   }
//   };



  const handleInputChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  

  return (
    <>
      <Stack spacing={3}>
        {/* <TextField name="newPassword" label="New Password" value={formData.email}    onChange={handleInputChange} />  */}
        
        <TextField 
          name="newPassword"
          label="New Password" 
          value={formData.newPassword}
          onChange={handleInputChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField 
          name="confirmPassword"
          label="confirm Password" 
          value={formData.confirmPassword}
          onChange={handleInputChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover"onClick={handleForget} >
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Change Password
      </LoadingButton>
      {errorMessage && <p>{errorMessage}</p>}
    </>
  );
}
