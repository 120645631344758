// import { useHistory } from 'react-router-dom';


import qs from 'qs';
import axios from 'axios';
// import fs from 'fs';
// import https from 'https-browserify';
import { USER_ACCESS_TOKEN, AUTH_HEADER } from '../constants/website';

// FOR TESTNET
// import { API } from '../api'; 

// FOR MAINNET
import { API, API1 } from '../api';  

// require('dotenv').config({path: ".env"});
// import 'dotenv/config';
// const fs = require('fs');
// const api = fs.readFileSync(".api").toString().trim();
// const baseUrl =   "https://mini.gamestar.exchange";
// const baseUrl = API; 


const baseUrl = API || API1;
// const baseUrl = API; 
// const history = useHistory();

// 携带登录信息
const requestConfig = (config) => {
  
  const { headers } = config
  const isBrowser = typeof document !== 'undefined'
  const auth = isBrowser ? localStorage.getItem(USER_ACCESS_TOKEN) : ''

  // console.log('Auth token:', auth);

  const requestHeaders = {
    ...headers
  }
//   auth && (requestHeaders[AUTH_HEADER] = auth || '')
if (auth) {
  requestHeaders[AUTH_HEADER] = `Bearer ${auth}`
}

  return {
    ...config,
    headers: requestHeaders
  }
}

const transformRequest = (data, headers) => {
  if (headers['Content-Type'] !== 'application/x-www-form-urlencoded') {
    return data
  }
  return qs.stringify(data, { arrayFormat: 'comma' })
}

// 处理2xx响应
const responseConfig = (response) => {
  const { data, status, statusText } = response
  if (status !== 200) {
    const err = new Error(statusText)
    err.code = status
    return Promise.reject(err)
  }
  return Promise.resolve(data)
}

// 处理错误
const errorConfig = (error) => {
  const { response } = error
  const { status, statusText, data } = response || {}
  // const history = useHistory();
  // if (config?.url !== '/user/profile' && status === 403) {
  //   localStorage.removeItem(USER_ACCESS_TOKEN)
   
  // }
  const message = data?.errorMsg || statusText || 'Network Error'
  const err = new Error(message)
  err.code = data?.errorCode || status
  if (error instanceof axios.Cancel) {
    err.userCanceled = true
  }
  return Promise.reject(err)
}

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest'
  },
  transformRequest: [transformRequest]
})

instance.interceptors.request.use(requestConfig)
instance.interceptors.response.use(responseConfig, errorConfig)

const generator = (method = 'GET') => (url, params, headers) => {
    const suffix =
      method === 'GET'
        ? qs.stringify(params, { arrayFormat: 'comma', addQueryPrefix: true })
        : ''
    return instance({
      method,
      url: suffix ? `${url}${suffix}` : url,
      data: params,
    //   headers: headers
      headers: headers || {}

    })
  }


export const get = generator('GET')

export const post = generator('POST')

export const put = generator('PUT')

export const del = generator('DELETE')

export default instance