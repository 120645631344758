// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Merchants Request',
    path: '/dashboard/approvemerchant',
    icon: icon('ic_lock'),
  },
  {
    title: 'Merchants',
    path: '/dashboard/merchants',
    icon: icon('ic_cart'),
  },
  {
    title: 'GiftCards TXN',
    path: '/dashboard/transactions',
    icon: icon('ic_disabled'),
  },
  {
    title: 'Crypto TXN',
    path: '/dashboard/cryptotransactions',
    icon: icon('ic_blog'),
  },
  {
    title: 'View Admin Address',
    path: '/dashboard/viewaddress',
    icon: icon('ic_blog'),
  },
 
 
];

export default navConfig;
